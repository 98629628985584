export const en = {
  title: 'Ads Instinct: ',
  desc: 'Your Reliable Android App Development Company',
  contacts: 'Contacts',
  services: 'Services',
  together: {
    label: 'Ads Instinct – ',
    desc: 'Creating the Future Together!',
  },
  aboutUs: {
    title: 'About Us',

    desc1: 'The company',
    instinct: '"Ads Instinct"',
    desc2:
      'specializes in the development of high-quality Android applications and their publication on the Google Play Market. With over 5 years of experience in the market, we provide our clients with innovative solutions that meet the most demanding needs.',

    services: {
      title: 'Our Services',
      android: {
        title: 'Android App Development',
        info: {
          java: {
            label: 'Java',
            desc: 'A classic language for Android app development, ensuring high performance and stability.',
          },
          kotlin: {
            label: 'Kotlin',
            desc: 'A modern programming language that simplifies development and enhances code security.',
          },
          c: {
            label: 'C++',
            desc: 'Used for developing high-performance apps and games that require access to low-level resources.',
          },
          python: {
            label: 'Python',
            desc: 'An ideal choice for rapid prototyping and applications with artificial intelligence integration.',
          },
          reactNative: {
            label: 'React Native',
            desc: 'A popular framework for building cross-platform apps using JavaScript and React.',
          },
          flutter: {
            label: 'Flutter',
            desc: "Google's Dart language for creating cross-platform apps with high performance and excellent design.",
          },
        },
      },
      playMarket: {
        title: 'Publishing Apps on Google Play Market',
        info: {
          1: 'We provide full support throughout the process of publishing your app, including the preparation of necessary materials, testing, and setup.',
          2: 'Our experts will help you navigate any possible obstacles and ensure the successful placement of your product.',
        },
      },
      adServices: {
        title: 'Ad Services Integration',
        info: {
          adMob: {
            label: 'AdMob',
            desc: 'Setting up monetization through banner, interstitial, and video ads from Google.',
          },
          appodeal: {
            label: 'Appodeal',
            desc: 'Optimizing ad revenue with interstitial, video, and native ads, including the option to create your own ad server.',
          },
          facebookNetwork: {
            label: 'Facebook Audience Network',
            desc: 'Integrating ads from Facebook for additional monetization of your app.',
          },
          unityAds: {
            label: 'Unity Ads',
            desc: 'Specialized solutions for games, including video and rewarded ads.',
          },
          moPub: {
            label: 'MoPub',
            desc: 'A platform for managing all your ad campaigns, maximizing revenue.',
          },
        },
      },
    },
  },
  whyWe: {
    title: 'Why Choose Us',
    info: {
      experience: {
        label: 'Experience and Professionalism',
        desc: 'With over 5 years in the market, we have completed hundreds of successful projects, allowing us to accumulate extensive experience and knowledge.',
      },
      approach: {
        label: 'Individual Approach',
        desc: "We listen to our clients' needs and offer unique solutions that meet their requirements.",
      },
      quality: {
        label: 'High Quality Standards',
        desc: 'We adhere to the highest quality standards, ensuring the reliability and stability of our products.',
      },
      solutions: {
        label: 'Comprehensive Solutions',
        desc: 'From idea to implementation and publication – we provide a full range of services for the successful launch of your apps.',
      },
    },
  },
  callUs: {
    title: 'Contact Us',
    desc: "If you have an idea for an app or need help with publishing an existing product, don't hesitate to reach out. Our specialists are always ready to provide you with expert consultation and help you realize your project.",
  },
};
