import { I18n } from 'i18n-js';
import translations from './lang';
import { ua } from './lang/ua';
import { en } from './lang/en';

const i18n = new I18n(translations);

i18n.defaultLocale = 'ua';
i18n.locale = 'ua';
i18n.fallbacks = true;

function updateText() {
  document.querySelectorAll('[data-i18n]').forEach((element) => {
    const key = element.getAttribute('data-i18n');
    element.textContent = i18n.t(key);
  });
}

updateText();

document.getElementById('change-lang').addEventListener('click', function () {
  if (i18n.locale === 'en') {
    i18n.locale = 'ua';
    this.textContent = 'EN';
  } else {
    i18n.locale = 'en';
    this.textContent = 'UA';
  }

  updateText();
});

console.log('dddd')