export const ua = {
  title: 'Ads Instinct: ',
  desc: 'Ваша надійна компанія з розробки Android додатків',
  contacts: 'Контакти',
  services: 'Наші послуги',
  together: {
    label: 'Ads Instinct – ',
    desc: 'створюємо майбутнє разом!',
  },
  aboutUs: {
    title: 'Про нас',
    desc1: 'Компанія',
    instinct: '"Ads Instinct"',
    desc2:
      'спеціалізується на розробці високоякісних Android додатків та їх публікації в Google Play Market. Ми маємо понад 5 років досвіду на ринку, що дозволяє нам забезпечити наших клієнтів інноваційними рішеннями, які задовольняють найвибагливіші потреби.',
    services: {
      title: 'Наші послуги',
      android: {
        title: 'Розробка Android додатків',
        info: {
          java: {
            label: 'Java',
            desc: 'Класична мова для розробки Android додатків, яка забезпечує високу продуктивність та стабільність.',
          },
          kotlin: {
            label: 'Kotlin',
            desc: 'Сучасна мова програмування, яка спрощує розробку та підвищує безпеку коду.',
          },
          c: {
            label: 'C++',
            desc: 'Використовується для розробки високопродуктивних додатків та ігор, які потребують доступу до низькорівневих ресурсів.',
          },
          python: {
            label: 'Python',
            desc: 'Ідеальний вибір для швидкої розробки прототипів та додатків з інтеграцією штучного інтелекту.',
          },
          reactNative: {
            label: 'React Native',
            desc: 'Популярний фреймворк для створення кросплатформних додатків з використанням JavaScript та React.',
          },
          flutter: {
            label: 'Flutter',
            desc: 'Мова Dart від Google для створення кросплатформних додатків з високою продуктивністю та чудовим дизайном.',
          },
        },
      },
      playMarket: {
        title: 'Публікація додатків в Google Play Market',
        info: {
          1: 'Ми забезпечуємо повний супровід процесу публікації вашого додатку, включаючи підготовку необхідних матеріалів, тестування та налаштування.',
          2: 'Наші експерти допоможуть вам обійти всі можливі перешкоди та забезпечать успішне розміщення вашого продукту.',
        },
      },
      adServices: {
        title: 'Інтеграція рекламних сервісів',
        info: {
          adMob: {
            label: 'AdMob',
            desc: 'Налаштовуємо монетизацію через банерні, міжсторінкові та відеореклами від Google.',
          },
          appodeal: {
            label: 'Appodeal',
            desc: 'Оптимізуємо дохід від реклами за допомогою міжсторінкових, відео- та нативних оголошень, включаючи можливість створення власного рекламного сервера.',
          },
          facebookNetwork: {
            label: 'Facebook Audience Network',
            desc: 'Інтегруємо рекламу від Facebook для додаткової монетизації вашого додатку.',
          },
          unityAds: {
            label: 'Unity Ads',
            desc: 'Спеціалізовані рішення для ігор, що включають відеореклами та винагороджувану рекламу.',
          },
          moPub: {
            label: 'MoPub',
            desc: 'Платформа для управління всіма вашими рекламними кампаніями, що дозволяє отримувати максимальний дохід.',
          },
        },
      },
    },
  },
  whyWe: {
    title: 'Чому обирають нас',
    info: {
      experience: {
        label: 'Досвід та професіоналізм: ',
        desc: 'За понад 5 років на ринку ми реалізували сотні успішних проектів, що дозволило нам накопичити великий досвід та знання.',
      },
      approach: {
        label: 'Індивідуальний підхід: ',
        desc: 'Ми прислухаємося до побажань наших клієнтів і пропонуємо унікальні рішення, що відповідають їхнім потребам.',
      },
      quality: {
        label: 'Високі стандарти якості: ',
        desc: 'Ми дотримуємося найвищих стандартів якості, що гарантує надійність та стабільність наших продуктів.',
      },
      solutions: {
        label: 'Комплексні рішення: ',
        desc: 'Від ідеї до реалізації та публікації – ми надаємо повний спектр послуг для успішного запуску ваших додатків.',
      },
    },
  },
  callUs: {
    title: "Зв'яжіться з нами",
    desc: 'Якщо у вас є ідея для додатку або вам потрібна допомога з публікацією існуючого продукту, не соромтеся звертатися до нас. Наші спеціалісти завжди готові надати вам кваліфіковану консультацію та допомогти в реалізації вашого проекту.',
  },
};
